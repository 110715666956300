import Head from "../../layout/head/Head";
import Content from "../../layout/content/Content";
import { Row, Col, Button } from "reactstrap";
import {
  Block,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
} from "../../components/Component";
import {
  Card,
  CardBody,
  CardTitle,
} from "reactstrap";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import Cookies from "js-cookie";
import CreateSubuser from "./components/CreateSubuser";
import DeleteSubuser from "./components/DeleteSubuser";
import ChangeSubuserPassword from "./components/ChangeSubuserPassword";
import ChangeSubuserEmail from "./components/ChangeSubuserEmail";

function TeamManagement() {
  const token = Cookies.get('token');
  const [subusers, setSubusers] = useState([]);

  const navigate = useNavigate();

  const fetchSubuserInfo = async () => {
    try {
      const url = `${global.SERVIP}/user/get-subusers`;
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: "{}",
      });
      if (!response.ok) return;
      const data = await response.json();
      if (!data.ok) return;

      setSubusers(data.subusers);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (!token) {
      navigate("/signup");
    }
    fetchSubuserInfo();
  }, []);

  return (
    <>
      <Head title="Team Management" />
      <Content>
        <h4 style={{ marginBottom: 10 }}>Manage Team Members</h4>
        <p style={{ marginBottom: 20 }}>
          Your team members can log in to their account, which has functionality restricted only to chat.
        </p>

        <Row>
          <Col lg="8">
            <Card className="card h-100">
              <CardBody className="card-inner">
                <CardTitle tag="h4">Manage Team Members</CardTitle>
                <br />
                <p>
                  Manage your team member subaccounts. <br />
                  When you create an account, the email address that you specify will receive a confirmation link, which your team member can use to set the password that they will use to log in to their account.
                  <br />
                  <strong>Note:</strong> The confirmation email might end up in the recipient's <strong>junk or spam folder</strong>. If your team member does not receive the email, please ask them to check their junk/spam folders.
                  <br />
                  Alternatively, you can manually set the user's password using the options below.
                </p>
                <br />
                <div
                  style={{
                    marginBottom: '20px',
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <table className={`table table-tranxf`}>
                    <thead>
                      <tr className="tb-tnx-item">
                        <th className="tb-tnx-info" style={{ paddingLeft: '8px' }}>
                          E-mail Address
                        </th>
                        <th className="tb-tnx-info">Status</th>
                        <th className="tb-tnx-info"></th>
                        <th className="tb-tnx-info"></th>
                        <th className="tb-tnx-info"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {subusers.map((item) => {
                        return (
                          <tr key={item.id} className="tb-tnx-item">
                            <td className="tb-tnx-info" style={{ paddingLeft: '8px' }}>
                              <span className="title">
                                {item.email || 'Error displaying email'}
                              </span>
                            </td>
                            <td className="tb-tnx-info">
                              <span className="title">
                                {item.password ? 'Active' : 'Pending Confirmation'}
                              </span>
                            </td>
                            <td className="tb-tnx-info">
                              <ChangeSubuserEmail
                                token={token}
                                id={item.id}
                                currentEmail={item.email}
                                onChangeEmail={(subuser) => {
                                  setSubusers((e) =>
                                    e.map((a) => (a.id === item.id ? subuser : a))
                                  );
                                }}
                              />
                            </td>
                            <td className="tb-tnx-info">
                              <ChangeSubuserPassword
                                token={token}
                                id={item.id}
                                hasPassword={item.password}
                                onChangePassword={(subuser) => {
                                  setSubusers((e) =>
                                    e.map((a) => (a.id === item.id ? subuser : a))
                                  );
                                }}
                              />
                            </td>
                            <td className="tb-tnx-info">
                              <DeleteSubuser
                                id={item.id}
                                token={token}
                                onDelete={() => {
                                  setSubusers((e) => e.filter((a) => a.id !== item.id));
                                }}
                              />
                            </td>
                          </tr>
                        );
                      })}
                      {subusers.length === 0 ? (
                        <tr>
                          <td colSpan={5} style={{ textAlign: 'center' }}>
                            You do not have team members.
                          </td>
                        </tr>
                      ) : null}
                    </tbody>
                  </table>
                  <CreateSubuser
                    token={token}
                    onCreate={(subuser) => setSubusers((e) => [...e, subuser])}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg="4">
            <Card className="card h-100">
              <CardBody className="card-inner">
                <CardTitle tag="h6">Having Trouble?</CardTitle>
                <p className="mb-2 ff-base">
                  Click on the <strong>"Create New"</strong> button and enter the email of the employee you want to add as a user to your account.
                  <br />
                  <br />
                  If your team member doesn't receive the confirmation email, please ask them to check their <strong>junk or spam folder</strong>.
                  <br />
                  You can also manually set their password by clicking on the <strong>"Change Password"</strong> option next to their account.
                  <br />
                  <br />
                  If you are having any issues, feel free to contact us.
                </p>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Content>
    </>
  );
}

export default TeamManagement;
