// AnalyticsHomePage.js

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import Cookies from 'js-cookie';

// Import custom components and layout
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import {
  Block,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Row,
  Col,
  PreviewAltCard,
} from "../../components/Component";
import { Card } from "reactstrap";

// Import charts and cards
import SendingSpendingChart from "./SendingSpendingChart";
import SubscriptionsCard from "./SubscriptionsCard";

const AnalyticsHomePage = () => {
  const [messagesByMonth, setMessagesByMonth] = useState([]);
  const [userSubscriptions, setUserSubscriptions] = useState([]);

  const navigate = useNavigate();
  const token = Cookies.get('token'); // Get the token from cookies

  // Fetch messages sent per month
  const fetchMessagesByMonth = async () => {
    const url = `${global.SERVIP}/analytics/get_messages_by_month`;
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`, // Set the authorization header
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      setMessagesByMonth(data.messages);
    } catch (error) {
      console.error('Error fetching messages by month:', error);
    }
  };

  // Fetch user's active subscriptions
  const fetchSubscriptions = async () => {
    const url = `${global.SERVIP}/user/get_subscriptions`;
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`, // Set the authorization header
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      setUserSubscriptions(data.subscriptions);
    } catch (error) {
      console.error('Error fetching subscriptions:', error);
    }
  };

  // Fetch all necessary data when component mounts
  useEffect(() => {
    if (!token) {
      navigate("/signup");
    } else {
      fetchMessagesByMonth();
      fetchSubscriptions();
    }
  }, []);

  return (
    <>
      <Head title="Balance and Overview" />
      <Content>
        <BlockHead size="sm">
          <div className="nk-block-between">
            <BlockHeadContent>
              <BlockTitle page tag="h3">
                Usage Analytics
              </BlockTitle>
              <BlockDes className="text-soft">
                <p>Get insights into your messaging activity and subscriptions.</p>
              </BlockDes>
            </BlockHeadContent>
          </div>
        </BlockHead>

        <Block>
          <Row className="g-gs">
            {/* Messages Sent and Spending Chart */}
            <Col lg="6" xxl="6" className="mb-3 mb-md-0">
              <PreviewAltCard className="h-100">
                <SendingSpendingChart messagesByMonth={messagesByMonth} />
              </PreviewAltCard>
            </Col>

            {/* Active Subscriptions Card */}
            <Col lg="6" xxl="6" className="mb-3 mb-md-0">
              <Card className="card-bordered h-100">
                <SubscriptionsCard subscriptions={userSubscriptions} />
              </Card>
            </Col>
          </Row>
        </Block>
      </Content>
    </>
  );
};

export default AnalyticsHomePage;
