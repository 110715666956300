import { Link } from "react-router-dom";
import React, { useState } from 'react';
import ModalVideo from 'react-modal-video';
import HomeWorkProcess from "./HomeWorkPorcess";

export default function HomeAbout2(){
	const [isOpen, setIsOpen] = useState(false);
    const openModal = () => setIsOpen(!isOpen);
    return(

        <section id="about" className="about-us-area pt-155 pb-120 pt-md-60 pb-md-30 pt-xs-60 pb-xs-30">

			<div className="container">
				<div className="row">
				<div className="col-lg-6">
						<div className="about-text-wrapper mb-30">
							<h2 className="style-text">tOOLS</h2>
							<h2 className="sect-title">More About The Setup Process</h2>
							<div className="quote-paragraph">
							<h3>What Do I Need To Start</h3>
								<br/>
								<p className="paragraph-title">
								Not much; everything you need is a spare Android phone, a SIM card that can inexpensively send SMS to desired locations, and a stable internet connection.</p>
							</div>
							<div className="quote-paragraph">
								<h3>How To</h3>
								<br/>
								<p className="paragraph-title">
								Once you register, you will be guided step by step, but to sum it up, <br/> Download our app onto the spare Android phone (it should already have the desired SIM card inside of it), log in with your TextLink account, and enable the country you want to send to. Once that is done, just connect it to a charger and hook onto a stable Wi-Fi connection. Now go back to your TextLink Dashboard on your browser, and you will be able to use all of our SMS tools.
								</p>
							</div>
							
						</div>
					</div>
					<ModalVideo channel='youtube' isOpen={isOpen} videoId='-gajhLRMYIE' onClose={() => { openModal(); }} />
					<div className="col-lg-6">
						<div className="about-video-wrapper mb-30">							
							<a className="popup-video" onClick={() => { openModal(); }}><i className="fa fa-play"></i></a>
							<img className="img-one" src="assets/img/about/smsapiImg1.png"  alt="About Img" />
						</div>
					</div>
					
				</div>
			</div>
			<HomeWorkProcess/>
		</section>
    )
}