import { Link } from "react-router-dom";
import React, { useState } from 'react';
import ModalVideo from 'react-modal-video';
export default function HomeAbout(){
	const [isOpen, setIsOpen] = useState(false);
    const openModal = () => setIsOpen(!isOpen);
    return(

        <section id="about" className="about-us-area pt-155 pb-30 pt-md-60 pb-md-30 pt-xs-60 pb-xs-30">

			<div className="container">
				<div className="row">
					<ModalVideo channel='youtube' isOpen={isOpen} videoId='-gajhLRMYIE' onClose={() => { openModal(); }} />
					<div className="col-lg-5">
						<div className="about-video-wrapper mb-30">							
							<a className="popup-video" onClick={() => { openModal(); }}><i className="fa fa-play"></i></a>
							<img className="img-one" src="assets/img/about/aboutImg1.png"  alt="About Img" />
						</div>
					</div>
					<div className="col-lg-7">
						<div className="about-text-wrapper mb-30">
							<h2 className="style-text">Services</h2>
							<h2 className="sect-title">Why Migrate To TextLink?</h2>
							<div className="quote-paragraph">
							<h3>Simplicity and Price</h3>
<br/>
								<p className="paragraph-title" style={{color:'black'}}>So in a few simple words. We are more reliable, have lower rates per message, and the setup is so quick and simple that it won't take you for more than an hour. <br/>
								Our tools are all up to industry standards, whether you want to send SMS to a single person via our web app (or API) or want to run your bulk SMS campaign to thousands of people for just a couple of dollars. We have you covered.</p>
							</div>
							<div className="quote-paragraph">
								<h3>Setup Assistance</h3>
								<br/>

								<p className="paragraph-title" style={{color:'black'}}>At TextLink, we are commited to each of our customers. <br/>That is why we offer you base setup help free of charge for this promotional period. If you decide to go with us, we will hop on a call with your engineers (or by ourselves if you don't have any) and set everything up for you.</p>
							</div>
						
						</div>
					</div>
				</div>
			</div>
		</section>
    )
}