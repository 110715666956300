import React, { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom';
import { Form, Input, Button, notification } from 'antd';
import { AuthWrapper } from './style';
import { Icon } from '../../Dashboard/components/Component';
import { Alert } from 'reactstrap';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import Head from '../../components/Head';
import { useLocation } from 'react-router-dom';

const SignUpAlert = ({ strongText, normalText, error }) => {
  return (
    <Alert className="alert-icon" color={error ? "danger" : "primary"}>
      <Icon name="alert-circle" />
      <strong>{strongText}</strong> {normalText}
    </Alert>
  )
}

function SignUpAppSumo() {
  const [oneTimeCode, setOneTimeCode] = useState('');
  const location = useLocation();

  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [repeatedPassword, setRepeatedPassword] = useState("")
  const [registrationComplete, setRegistrationComplete] = useState(false)
  const [registrationError, setRegistrationError] = useState("")
  const [state, setState] = useState({
    values: null,
    checked: null,
  });
  const navigate = useNavigate()
  const signedIn = Cookies.get('token') ? true : false;

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const codeFromURL = queryParams.get('accessToken');
    if (codeFromURL) {
      setOneTimeCode(codeFromURL);
    }
  }, [location.search]);

  useEffect(() => {
    if (signedIn) {
      Cookies.remove('token');
      Cookies.remove('subuser');
    }
  }, [signedIn, navigate]);

  const checkValues = () => {
    if (!(password == repeatedPassword)) {
      alert("Passwords do not match")
      return false
    }
    if (password.length < 8) {
      notification.error({
        message: "Password must be at least 8 characters long, use both numbers and letters for better security.",
      })
      return false
    }
    return true
  }
  const handleSubmit = async (event) => {
    const correct = checkValues()
    if (!correct)
      return
    try {
      const response = await fetch(global.SERVIP + '/auth/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email,
          password,
          access_token: oneTimeCode,
          app_sumo: true
        })
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      if (data.ok) {
        setRegistrationComplete(true)
        setTimeout(() => {
          setRegistrationComplete(false);
        }, 20000)
      }
      else {
        setRegistrationError(data.message || "Error creating an account")
        setTimeout(() => {
          setRegistrationError("");
        }, 5000)
      }
      // Handle further actions here after successful login
    } catch (error) {
      notification.error({
        message: error,
      })
      // Handle errors here
    }
  };

  if (!oneTimeCode)
    return <div style={{ textAlign: "center", marginTop: '30vh', fontWeight: 'bold', fontSize: '2em' }}>Loading...</div>

  return (
    <AuthWrapper>
      <Head title="Sign Up Page" />
      {registrationError && <SignUpAlert strongText={"Error signing up:"} normalText={registrationError} error={true}></SignUpAlert>}
      {registrationComplete && <SignUpAlert error={false} strongText={"Confirmation email has been sent to"} normalText={`${email}. Click the link we have sent you to complete your registration.`} />}
      <div className="auth-contents">
        <Form name="register" onFinish={handleSubmit} layout="vertical">
          <h3 style={{ marginBottom: '30px', textAlign: 'center' }}>Welcome, Sumoling!</h3>
          <p style={{ textAlign: 'center', marginTop: "15px", marginBottom: '15px', fontWeight: "bold", color: "black" }}>
            Your account is almost ready
          </p>
          <Form.Item
            name="email"
            label="Email Address"
            rules={[{ required: true, message: 'Please input your email!', type: 'email' }]}
          >
            <Input onChange={(e) => setEmail(e.target.value)} placeholder="name@example.com" />
          </Form.Item>
          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: 'Please input your password!' }]}
          >
            <Input.Password onChange={(e) => setPassword(e.target.value)} placeholder="Password" />
          </Form.Item>
          <Form.Item
            label="Repeat Password"
            name="password2"
            rules={[{ required: true, message: 'Please use the same your password!' }]}
          >
            <Input.Password onChange={(e) => setRepeatedPassword(e.target.value)} placeholder="Repeat password" />
          </Form.Item>
          <div className="auth-form-action">

          </div>
          <Form.Item>
            <Button className="btn-create" htmlType="submit" type="primary" size="large" style={{ width: '100%', }}>
              Create Account
            </Button>

          </Form.Item>
        </Form>
      </div>
    </AuthWrapper>
  );
}

export default SignUpAppSumo;
