import React, { useState } from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import OrderOverview from "./components/order-overview/OrderOverview";
import ActionCenter from "./components/action-center/ActionCenter";
import CoinOrder from "./components/coin-order/CoinOrder";
import UserActivity from "./components//user-activity/UserActivity";
import OrderActivity from "../../../components/partials/order-activity/OrderActivity";
import { Card, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown, Modal,
    ModalHeader,
    ModalBody,
    ModalFooter, } from "reactstrap";
import {
  Block,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockBetween,
  BlockTitle,
  PreviewAltCard,
  Icon,
  Button,
  Row,
  Col,
  SpecialTable,
} from "../../../components/Component";
import { useNavigate } from "react-router";
import EarningsChart from "./components/EarningsChart";
import Cookies from "js-cookie";
import { useEffect } from "react";
import DevicesTable from "./components/DevicesTable";
import SellingAnalyticsDataCard from "./components/SellerAnalyticsDataCard";

import './styles/table.css'

const SellingDashboardHome = () => {
  const [sm, updateSm] = useState(false);
  const [modalShown,setModalShown]= useState(false);  
  const [currentDevice,setCurrentDevice]= useState({});
  const [earningsPerMonth,setEarningsPerMonth] = useState([])
  const [devicesData,setDevicesData] = useState([])
  const [sellerData,setSellerData] = useState({})
  const [currentSims,setCurrentSims] = useState([])
  const token = Cookies.get('token'); // Get the token from cookies

  const navigate = useNavigate()

  async function showSimsForDevice(item){
    setModalShown(!modalShown);
    if(item){
        setCurrentDevice(item)
        await fetchDeviceSims(item.Id)
      }
  }

  const fetchDeviceSims = async (deviceId) => {
    try {
        const response = await fetch(global.SERVIP+`/device/get-sims/${deviceId}`); // Adjust the URL as needed
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        setCurrentSims(data.simCards)
        return data;
    } catch (error) {
        console.error('Error fetching device SIM cards:', error);
    }
};

  const fetchEarningsByMonth = async () => {
    const url = `${global.SERVIP}/analytics/get_earnings_by_month`;
  
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}` // Set the authorization header
        }
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      const data = await response.json();
      setEarningsPerMonth(data.earnings)
      return data;
    } catch (error) {
      console.error('Error fetching messages by month:', error);
      throw error;
    }
  };
  const fetchUserDevices = async () => {
    const url = `${global.SERVIP}/user/get_user_devices_info`; // Update the endpoint URL
  
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}` // Set the authorization header
        }
      });
  
      if (!response.ok) {
        console.log(`HTTP error! Status: ${response.status}`);
      }
  
      const data = await response.json();
      setDevicesData(data.devices)
      // You can set state or process data here as needed
      return data;
    } catch (error) {
      console.error('Error fetching user devices:', error);
      throw error;
    }
  };

  const fetchSellerStatistics = async () => {
    const url = `${global.SERVIP}/analytics/get_seller_statistics`;
  
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}` // Set the authorization header
        }
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      const data = await response.json();
      console.log("Fetched User Statistics:", data.statistics); // Debugging
      setSellerData(data.statistics)
      // Handle the fetched statistics data as needed
      // Example: setUserStatistics(data.statistics)

      return data;
    } catch (error) {
      console.error('Error fetching user statistics:', error);
      throw error;
    }
};
  const fetchAllData = async ()=>{
    if(!token)
    {
      navigate("/signup")
    }
   
    await fetchEarningsByMonth()
    await fetchUserDevices()
    await fetchSellerStatistics();
  }

useEffect(() => {
  fetchAllData();
  }, []);
  return (
    <>
      <Head title="Seller's Dashboard" />
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page>Overview</BlockTitle>
              <BlockDes className="text-soft">
                <p>This is a page to monitor/edit your selling on TextLink.</p>
              </BlockDes>
            </BlockHeadContent>
           
          </BlockBetween>
        </BlockHead>
        <Block>
          <Row className="g-gs">
            <Col lg="6" className="mb-4 mb-lg-0">
              <PreviewAltCard className="h-100">
                <EarningsChart earningsData={earningsPerMonth} />
              </PreviewAltCard>
              
            </Col>
           
            <Col lg="6">
            <Row>
              <Card className="h-100">
                <DevicesTable showSimsForDevice={showSimsForDevice} devicesData={devicesData} />
              </Card>
              </Row>
              <br/>

              <Row>
                <br/>
              <PreviewAltCard className="h-100" >
                

               <SellingAnalyticsDataCard data={sellerData}/>
              </PreviewAltCard>
                </Row>
            </Col>
           
          </Row>
          <Modal isOpen={modalShown} toggle={() => setModalShown(false)}>
  <ModalHeader
    toggle={() => setModalShown(false)}
    close={
      <button className="close" onClick={() => setModalShown(false)}>
        <Icon name="cross" />
      </button>
    }
  >
    {currentDevice.name}
  </ModalHeader>
  <ModalBody>
    <table className="table">
      <thead>
        <tr>
          <th scope="col">ID</th>
          <th scope="col">Provider</th>
          <th scope="col">Country</th>
          {/* Include other columns as needed */}
          <th scope="col">Actions</th> {/* Added Actions column */}
        </tr>
      </thead>
      <tbody>
        {currentSims.map((sim, index) => (
          <tr key={index}>
            {/* Removed onClick from <tr> */}
            <th scope="row">{index + 1}</th>
            <td>{sim.name}</td>
            <td>{sim.sourceCountry}</td>
            {/* Include other data as needed */}
            <td>
              <button
                onClick={() => navigate('/dashboard/edit/sim', { state: { id: sim.id } })}
                className="btn btn-primary btn-sm"
              >
                Edit
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
    <p>
      This is the list of your SIM cards for {currentDevice.name? currentDevice.name : "this device"}, select which one you want to edit
      (add/remove countries or change content type).
    </p>
  </ModalBody>
  <ModalFooter className="bg-light">
    <span className="sub-text">
    <a href="https://youtu.be/nyVm4U5iScg" target="_blank" style={{ color: 'blue' }}>
        Quick tutorial
      </a>
    </span>
  </ModalFooter>
</Modal>
        </Block>
      </Content>
    </>
  );
};

export default SellingDashboardHome;
