import React, { useEffect, useState } from "react";
import {
  Row,
  BlockBetween,
  BlockDes,
  Block,
  BlockContent,
  BlockHead,
  BlockTitle,
  Col,
} from "../../components/Component";
import { Button, Alert } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import SubscriptionComponent from './components/SubsctiptionComponent'
import Cookies from "js-cookie";
import AppSumoSubscriptionComponent from "./components/AppSumoSubsctiptionComponent";

const appSumoSubscriptions = [
  { tier: 1, price: 39, message_limit: 5000 },
  { tier: 2, price: 69, message_limit: 10000 },
  { tier: 3, price: 139, message_limit: 25000 },
  { tier: 4, price: 249, message_limit: 100000 },
  { tier: 5, price: 399, message_limit: 200000 },
]

function SubscriptionsPricing({ myTargetRef }) {
  const navigate = useNavigate();
  const token = Cookies.get('token');
  const [subscriptions, setSubscriptions] = useState([]);
  const [lookupKey, setLookupKey] = useState('free');
  const [yearlyPlan, setYearlyPlan] = useState(false); // Global state for yearly/monthly
  const [hasDevices, setHasDevices] = useState(false); // New state to check if user has devices

  useEffect(() => {
    const fetchSubscriptions = async () => {
      try {
        const response = await fetch(`${global.SERVIP}/subscriptions/get-all`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          }
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        data.subscriptions.sort((a, b) => (a.price - b.price));
        setSubscriptions(data.subscriptions);
      } catch (error) {
        console.error('Error fetching subscriptions:', error);
        // Handle errors here
      }
    };

    fetchSubscriptions();

    const fetchSubscriptionData = async () => {
      try {
        const response = await fetch(`${global.SERVIP}/payment/get-subscription-info`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
          }
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const { ok, lookup_key } = await response.json();
        if (!ok) {
          return;
        }

        setLookupKey(lookup_key);
      } catch (error) {
        console.error('Error fetching subscriptions:', error);
      }
    };

    fetchSubscriptionData();

    // New: Check if user has devices set up
    const fetchDevices = async () => {
      try {
        const response = await fetch(`${global.SERVIP}/devices/user-devices`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
          }
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        if (data.devices && data.devices.length > 0) {
          setHasDevices(true);
        } else {
          setHasDevices(false);
        }
      } catch (error) {
        console.error('Error fetching user devices:', error);
      }
    };

    fetchDevices();

  }, []);

  const getUsersSubscription = (subscriptions, lookup_key) => {
    for (let subscription of subscriptions) {
      if (subscription.lookup_key === lookup_key) {
        return subscription;
      }
    }
    return { lookup_key: 'free', price: 0 };
  };

  return (
    <>
      <BlockHead size="sm">
        <BlockBetween className="g-3">
          <BlockContent>
            <BlockTitle>Purchase Subscriptions</BlockTitle>
            <br />
            <BlockDes className="text-soft" style={{ margin: 10 }}>
              {/* Reminder Message */}
              {(!lookupKey.startsWith('sumo')) && !hasDevices && (
                <Alert color="warning">
                  <strong>Reminder:</strong> Before purchasing a subscription, please set up your Android device with a SIM card. Follow the <Link to="/dashboard/your-devices/get-started" style={{ color: '#007bff', textDecoration: 'underline', cursor: 'pointer' }}>
                    setup guide
                  </Link>
                </Alert>
              )}
              {/* Existing content */}
              {(!lookupKey.startsWith('sumo')) && <Button onClick={() => navigate("/dashboard/your-devices/get-started")} style={{ color: 'black' }} color="light">How do subscriptions work?</Button>}
            </BlockDes>
          </BlockContent>
        </BlockBetween>
      </BlockHead>


      {(!lookupKey.startsWith('sumo')) && <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '20px 0' }}>
        <span style={{ marginRight: '10px' }}>Monthly</span>
        <input
          type="checkbox"
          className="theme-checkbox"
          checked={yearlyPlan}
          onChange={() => setYearlyPlan(!yearlyPlan)}
        />
        <span style={{ marginLeft: '10px' }}>Yearly</span>
      </div>}

      {(!lookupKey.startsWith('sumo')) && <Block>
        <Row className="g-gs">
          {subscriptions.map((item) => (
            <Col md={4} lg={3} xl={3} key={item.id} className="mb-3">
              <SubscriptionComponent
                usersSubscription={getUsersSubscription(subscriptions, lookupKey)}
                item={item}
                yearlyPlan={yearlyPlan} // Pass the global state
              />
            </Col>
          ))}
          <div style={{ width: '100%', height: '15px' }}></div>
        </Row>
        <p>
          If you need larger limits or custom integration, contact us at
          <a href="mailto:sales@textlinksms.com" style={{ color: 'blue' }}> sales@textlinksms.com</a>
        </p>
      </Block>}
      {(lookupKey.startsWith('sumo')) && <div>Since you are subscribed using AppSumo plan, you can view and manage your subscription on <a target='_blank' style={{ color: 'blue' }} href="https://appsumo.com/account/products/">AppSumo products page</a>.</div>}

      <div style={{ marginTop: 20 }}></div>
      {lookupKey.startsWith('sumo') && (
        <Block>
          <Row className="g-gs justify-content-center">
            {appSumoSubscriptions.slice(0, 3).map((item) => (
              <Col sm={6} md={4} lg={3} key={"sumo" + item.tier.toString()} className="mb-3 px-2 d-flex justify-content-center">
                <AppSumoSubscriptionComponent
                  tier={item.tier}
                  price={item.price}
                  message_limit={item.message_limit}
                  active={lookupKey == 'sumo' + item.tier}
                />
              </Col>
            ))}
          </Row>
          <Row className="g-gs justify-content-center">
            {appSumoSubscriptions.slice(3).map((item) => (
              <Col sm={6} md={4} lg={3} key={"sumo" + item.tier.toString()} className="mb-3 px-2 d-flex justify-content-center">
                <AppSumoSubscriptionComponent
                  tier={item.tier}
                  price={item.price}
                  message_limit={item.message_limit}
                  active={lookupKey == 'sumo' + item.tier}
                />
              </Col>
            ))}
          </Row>
          <p style={{ textAlign: 'center', marginTop: '1rem' }}>
            If you need larger limits or custom integration, contact us at
            <a href="mailto:sales@textlinksms.com" style={{ color: 'blue' }}> sales@textlinksms.com</a>
          </p>
        </Block>
      )}
    </>
  );
}

export default SubscriptionsPricing;
