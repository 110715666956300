import { Link } from "react-router-dom";
import React, { useState } from 'react';
import ModalVideo from 'react-modal-video';

export default function HomeAbout3() {
  const [isOpen, setIsOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const openModal = () => setIsOpen(!isOpen);

  const openImageModal = () => {
    setIsModalOpen(true);
  };

  const closeImageModal = () => {
    setIsModalOpen(false);
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <section id="about" className="about-us-area pt-155 pb-30 pt-md-60 pb-md-30 pt-xs-60 pb-xs-30">
      <div className="container">
        <div className="row">
          <div className="col-lg-7">
            <div className="about-text-wrapper mb-30">
              <h2 className="style-text">Tools</h2>
              <h2 className="sect-title">Tools that we offer</h2>
              <div className="quote-paragraph">
                <h3>Dispatch Communication App</h3>
                <br />
                <p className="paragraph-title" style={{ color: 'black' }}>
                Easily communicate with your drivers using our web chat. You can receive messages, create and customise contacts, and send messages that deliver instantly.
                                </p>
              </div>
			  <div className="quote-paragraph">
  <h3>Bulk SMS campaigns</h3>
  <br />
  <p className="paragraph-title" style={{ color: 'black' }}>
	Use our no-code solution to send bulk messages to your customers. Just upload an Excel or CSV with info for each contact and craft messages with that information. <br/>If you are not a fan of that, you can also use our Bulk SMS API.
  </p>
</div>
<div className="quote-paragraph">
  <h3>Two Way SMS API</h3>
  <br />
  <p className="paragraph-title" style={{ color: 'black' }}>
  Verify users, send messages, receive messages via webhooks—everything an SMS API should be able to do, ours can. It's simple to set up (we have tutorials for different languages), and we have packages for JS, Python, C#,...  </p>
</div>

            </div>
          </div>
          <ModalVideo channel='youtube' isOpen={isOpen} videoId='-gajhLRMYIE' onClose={openModal} />
          <div className="col-lg-5">
            <div className="about-video-wrapper mb-30">
              <img 
                className="img-one" 
                src="assets/img/about/trucking.png"  
                alt="About Img" 
                style={{ marginBottom: "20px" }} 
              />
              <img 
                className="img-one" 
                src="assets/img/about/chat.png" 
                alt="About Img"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onClick={openImageModal}
                style={{ 
                  cursor: 'pointer',
                  transform: isHovered ? 'scale(1.05)' : 'scale(1)',
                  transition: 'transform 0.3s ease-in-out',
                  border: isHovered ? '2px solid #000' : 'none',
                }} 
              />
            </div>
          </div>
        </div>
      </div>
      {isModalOpen && (
        <div 
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000,
          }}
          onClick={closeImageModal}
        >
          <img 
            src="assets/img/about/chat.png" 
            alt="Enlarged Img" 
            style={{
              maxWidth: '90%',
              maxHeight: '90%',
              borderRadius: '10px',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
            }}
          />
        </div>
      )}
    </section>
  );
}
