import React, { useEffect, useState } from "react";
import Head from "../../layout/head/Head";
import Content from "../../layout/content/Content";

import { DropdownToggle, DropdownMenu, Card, UncontrolledDropdown, DropdownItem } from "reactstrap";
import {
  Block,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
  Row,
  Col,
  PreviewCard,
  PreviewAltCard,
  BlockBetween,
  UserAvatar,

} from "../../components/Component";
import Cookies from 'js-cookie';
import { useNavigate } from "react-router-dom";
import { OverlineTitle } from "../../components/Component";
import { LinkItem } from "../../components/Component";
import Accordion from "../../components/partials/accordian/Preview";
import TestApiFAQ from "./TestApiFAQ";
import SendMessageForm from "./SendMessageForm";
import { Alert } from 'reactstrap';
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { Select } from 'antd';
const { Option } = Select;

const TestApi = () => {
  const [sims, setSims] = useState([]);
  const [selectedSim, setSelectedSim] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [countries, setCountries] = useState([])
  const navigate = useNavigate()
  const token = Cookies.get('token');

  async function getAllSenders() {
    const url = global.SERVIP + '/user/get-all-senders';
    const jwtToken = Cookies.get('token');
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${jwtToken}`
        },
        body: JSON.stringify({})
      });

      if (!response.ok) {
        return;
      }

      const data = await response.json();

      if (!data.ok)
        return;
      const arr = [];
      for (const device of data.senders) {
        const device_name = "Device " + device.id;
        for (const sim_card of device.sim_cards) {
          arr.push({
            id: sim_card.id,
            name: sim_card.name,
            device_name
          })
        }
      }
      arr.sort((a, b) => {
        if (a.id < b.id) return -1;
        if (a.id > b.id) return 1;
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      })
      setSims(arr);
    } catch (error) {
      console.error('Error sending message:', error);
      throw error;
    }
  }

  useEffect(() => {
    if(!token)
    {
      navigate("/signup")
    }
    const fetchCountries = async () => {
      try {
        const response = await fetch(`${global.SERVIP}/countries/get-all`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        data.sort((a, b) => {
          if (a.name < b.name)
            return -1;
          if (a.name > b.name)
            return 1;
          return 0;
        }
        );
        setCountries(data);
      } catch (err) {
      } finally {
      }
    };

    fetchCountries();
    getAllSenders();
  }, [])

  async function sendMessage(phoneNumber, text) {
    const url = global.SERVIP + '/user/send-sms';

    // Get JWT token from cookies
    const jwtToken = Cookies.get('token');

    // Prepare the request body
    const requestBody = {
      phone_number: phoneNumber,
      text: text,
      source_country: selectedSim ? null : selectedCountry,
      sim_card_id: selectedSim
    };

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${jwtToken}` // Set JWT token in the Authorization header
        },
        body: JSON.stringify(requestBody)
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      if (!data.ok)
        return Swal.fire({ icon: "error", title: "Error", text: data.message });

      return Swal.fire({ icon: "success", title: "Message has been sent", text: "Depending on the service, it might take from 0 to 15 seconds to receive it." });

    } catch (error) {
      console.error('Error sending message:', error);
      throw error;
    }
  }

  return (
    <>
      <Head title="Test SMS services"></Head>
      <Content >
        <Block size="md">
          <BlockHead>
            <BlockHeadContent>
              <BlockTitle tag="h5">Test SMS services</BlockTitle>
              <BlockDes>
                <p>Send a message to anyone.</p>
                <br />
                <br />

              </BlockDes>
            </BlockHeadContent>
          </BlockHead>


        </Block>
        <Row>
          <Col lg="7" md="7" className="mb-3 mb-md-0">
            <PreviewCard className="card h-100">
              <SendMessageForm id="form-1" sendMessage={sendMessage} />
            </PreviewCard>
          </Col>
          <Col md="5" lg="5">
            <Card style={{ padding: 10 }} className="card h-100">
              <OverlineTitle className="preview-title">Select which device/sim you want to use</OverlineTitle>
              <ul className="custom-control-group custom-control-vertical w-100">
               
                {sims.map(sim => <li key={sim.id}>
                  <div className="custom-control custom-control-sm custom-radio custom-control-pro">
                    <input type="radio" className="custom-control-input" name="paymentCheck" id={"paymentCheck" + sim.id} checked={selectedSim == sim.id} onChange={(e) => {
                      if (e.target.checked)
                        setSelectedSim(sim.id)
                    }
                    } />
                    <label className="custom-control-label" htmlFor={"paymentCheck" + sim.id} style={{ display: 'flex', flexDirection: 'column' }}>
                      <Icon className="icon-lg" name="mobile"></Icon>
                      <span>{sim.device_name + "/" + sim.name}</span>
                    </label>
                  </div>
                </li>)}

              </ul>
              {(selectedSim == null) && <div style={{ marginTop: 20, textAlign: "center" }}>
              
                <Select onClick={(e) => { e.stopPropagation() }} value={selectedCountry} defaultValue={null} placeholder="Select sender country" style={{ flexGrow: 1, width: "80%", textAlign: "center" }} onChange={setSelectedCountry}>
                  <Option value={null}>Any country</Option>
                  {countries.filter(e => e.calling_code).map(country => <Option value={country.iso2}>{country.name} ({country.calling_code.startsWith('+') ? country.calling_code : ('+' + country.calling_code)})</Option>)}
                </Select>
              </div>}
              <Link to="/dashboard/your-devices/get-started" style={{ textDecoration: 'none' }}>
              <Button className="btn-dim" color="primary" style={{marginTop:20, width:'100%'}}>
               <Icon name="mobile"><a style={{fontFamily:'sans-serif',fontSize:'0.7em'}}>Set up your device</a></Icon>
                </Button>
</Link>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card className="card" style={{ marginTop: 10, padding: 20 }}>
              <TestApiFAQ />

            </Card>
          </Col>
        </Row>
      </Content>

    </>
  );
};
export default TestApi;
