import React from "react";
import { Card, CardBody } from "reactstrap";
import Cookies from "js-cookie";
import { useNavigate } from "react-router";
import '../styles/ToggleSwitch.css';

function AppSumoSubscriptionComponent({ tier, price, message_limit, active }) {
  const navigate = useNavigate();

  const cardStyle = {
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    overflow: 'hidden',
    height: '300px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '1.2rem',
    backgroundColor: '#f7f9fc', // Light blue background
    color: '#2c3e50', // Dark neutral blue-gray for text
    cursor: 'pointer',
    transition: 'transform 0.2s',
  };

  const titleStyle = {
    fontSize: '1.3rem',
    fontWeight: 'bold',
    color: '#7f8dff', // Soft blue accent for title
    marginBottom: '0.4rem',
  };

  const priceStyle = {
    fontSize: '1.5rem',
    fontWeight: '600',
    color: '#7f8dff', // Consistent soft blue accent for price
    marginBottom: '0.5rem',
  };

  const featureListStyle = {
    listStyle: 'none',
    paddingLeft: '0',
    marginTop: '0.8rem',
    fontSize: '0.9rem',
    color: '#4a4a4a', // Subtle dark gray for details
  };

  const badgeStyle = {
    position: 'absolute',
    top: '12px',
    right: '12px',
    backgroundColor: '#b2c2ff', // Muted blue badge for accent
    color: '#ffffff',
    padding: '4px 8px',
    borderRadius: '6px',
    fontSize: '0.75rem',
    fontWeight: 'bold',
  };

  const buttonStyle = {
    backgroundColor: '#5f73ff', // Soft blue button for CTA
    color: '#ffffff',
    width: '100%',
    padding: '10px',
    borderRadius: '6px',
    textAlign: 'center',
    fontSize: '1rem',
    fontWeight: '600',
    marginTop: 'auto',
  };

  return (
    <Card style={cardStyle}>
      {active && <div style={badgeStyle}>Your Current Plan</div>}
      <CardBody style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <div style={titleStyle}>Tier {tier}</div>
        <div style={priceStyle}>${price} USD</div>
        <ul style={featureListStyle}>
          <li>Monthly SMS Quota: {message_limit}</li>
        </ul>
        <a target='_blank' href="https://appsumo.com/account/products/" rel="noopener noreferrer">
          <div style={buttonStyle}>{active ? "Manage" : "Change"} Plan</div>
        </a>
      </CardBody>
    </Card>
  );
}

export default AppSumoSubscriptionComponent;
