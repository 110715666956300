import { Link } from "react-router-dom";
import Breadcrumb from "../../components/BreadCurmb";
import FooterOne from "../../footer/FooterOne";
import HeaderOne from "../../header/HeaderOne";
import { useNavigate } from "react-router-dom";

function UseYourOwnNumbers() {
	const navigate = useNavigate()
	return (
		<>
			<HeaderOne />
			<Breadcrumb pagetitle={"Download App"} subtitle={"Get started with TextLink"} title={"Download our app"} />
			<section className="about-services-area pb-60 pt-md-60 pb-md-30 pt-xs-60 pb-xs-30">
				<div className="container">
					<div className="row">
						<div className="col-lg-6">
							<div className="about-services-img mb-30">
								<img className="img-one img-fluid" src="assets/img/services/useyournumbersServiceImg1.png" alt="Service Img" />
							</div>
						</div>
						<div className="col-lg-6">
							<div className="about-services-text ps-xxl-5 mb-30 pt-30">
								<h3 className="service-title-1 mb-30">Simple setup, much lower cost</h3>
								<p className="mb-25">If you have your own Android device and a SIM card, this service is for you.
									Once you have set up the app, you will be able to use all of our tools to send any messages you want through your device.
								</p>
								<p className="mb-25">
									You get 500 messages for free to start (No credit card required).
								</p>
								<a href="/textlink.apk" className="theme_btn theme_btn3">Download App<i
									className="fal fa-long-arrow-right"></i></a>
								<br />
								<div className="plan-box d-sm-flex align-items-center" style={{ marginTop: 30 }}>
									<div className="icon d-flex align-items-center">
										<img src="assets/img/icon/s-5a.png" alt="Icon" />
										<h5>Do you have any questions, check the FAQ?</h5>
									</div>
									<Link className="d-block" to="/faq" style={{ backgroundColor: 'white', padding: 20, borderRadius: 5 }}><i className="fal fa-long-arrow-right"></i></Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="grey-bg2 services-tools pb-190 pt-md-60 pb-md-60 pt-xs-60 pb-xs-30">
				<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
					<h3 className="service-title-1 mb-30">Tools available with this service</h3>
				</div>
				<div className="container">
					<div className="row">
						<div className="col-lg-3 col-md-6" onClick={() => navigate("/bulksms")} style={{ cursor: 'pointer' }}>
							<div className="white-bg tool-box text-center mb-30">
								<img src="assets/img/icon/s-6a.png" alt="Icon" />
								<h5 className="fs-20">No-code Bulk SMS</h5>
								<p className="fs-14">Send 1000's of texts without code from our console</p>
								<div className="number">02</div>
							</div>
						</div>
						<div className="col-lg-3 col-md-6" onClick={() => navigate("/smsapi")} style={{ cursor: 'pointer' }}>
							<div className="white-bg tool-box text-center mb-30" >
								<img src="assets/img/icon/s-6b.png" alt="Icon" />
								<h5 className="fs-20">SMS API</h5>
								<p className="fs-14">Use our intuitive API to send any type of message to anyone</p>
								<div className="number">02</div>
							</div>
						</div>
						<div className="col-lg-3 col-md-6" onClick={() => navigate("/verificationapi")} style={{ cursor: 'pointer' }}>
							<div className="white-bg tool-box text-center mb-30">
								<img src="assets/img/icon/s-6c.png" alt="Icon" />
								<h5 className="fs-20">Verification API</h5>
								<p className="fs-14">Verify your users through their phone numbers</p>
								<div className="number">02</div>
							</div>
						</div>
						<div className="col-lg-3 col-md-6">
							<div className="white-bg tool-box text-center mb-30" onClick={() => navigate("/bulksmsapi")} style={{ cursor: 'pointer' }}>
								<img src="assets/img/icon/s-6d.png" alt="Icon" />
								<h5 className="fs-20">Bulk SMS API</h5>
								<p className="fs-14">Send SMS in bulk with our API (limits apply)</p>
								<div className="number">02</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<FooterOne />
		</>
	)
}

export default UseYourOwnNumbers