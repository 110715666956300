import React, { useState } from 'react';
import "./CookieConsent.css"
import Cookies from 'universal-cookie';
import { FaTimes } from 'react-icons/fa';

const cookiesOptions = [
    {
        id: 0,
        text: "Essential and functionality",
        values: ["functionality_cookies"]
    },
    {
        id: 1,
        text: "All cookies",
        values: ["functionality_cookies", "targeting_cookies"]
    }
]

const CookieConsent = () => {
    const cookies = new Cookies();
    const [selected, setSelected] = useState(1); // Default to "All cookies"
    const [acknowledged, setAcknowledged] = useState(cookies.get("acknowledged_cookies"));

    // Handle the change of radio buttons
    const handleRadioChange = (id) => {
        setSelected(id);
    };

    // Placeholder for the logic to save user's selection
    const handleAcceptClick = (e) => {
        e.preventDefault();
        e.stopPropagation();

        // Set the acknowledged_cookies cookie to true
        cookies.set('acknowledged_cookies', true, { path: '/', expires: new Date(Date.now() + 365 * 86400 * 1000) });

        // Set the other selected cookies to true
        cookiesOptions[selected].values.forEach(cookieName => {
            cookies.set(cookieName, true, { path: '/', expires: new Date(Date.now() + 365 * 86400 * 1000) });
        });
        window.location.reload();
    };
    const handleCloseClick = () => {
        // Only set the acknowledged_cookies cookie to true
        cookies.set('acknowledged_cookies', true, { path: '/', expires: new Date(Date.now() + 365 * 86400 * 1000) });
        setAcknowledged(true);
    };

    return (
        <div className='cookie-consent-container' style={{ display: acknowledged ? "none" : "flex" }}>
            <div className={"cookies-x-phone"}>
                <FaTimes size={24} style={{ cursor: 'pointer' }} onClick={handleCloseClick} />
            </div>
            <div className='cookies-description-section'>
            TextLink requires you to set up an Android device with a SIM card. That's how we offer messages for $0.0001 with all the tools you expect from typical SMS providers.
            We use cookies to enhance your experience, ensure our site's functionality, and gather usage insights. Change your preference or learn more on our <a style={{color: 'blue'}} href='/cookies'>Cookies</a> page.
            </div>

            <div className='cookies-selection-section'>
                <div style={{ textAlign: "left", marginTop: 15, marginBottom: 15 }}>
                    {
                        cookiesOptions.map(option => (
                            <div key={option.id}>
                                <input
                                    type="radio"
                                    id={`cookie-option-${option.id}`}
                                    name="cookieOptions"
                                    value={option.id}
                                    checked={selected === option.id}
                                    onChange={() => handleRadioChange(option.id)}
                                />
                                <label htmlFor={`cookie-option-${option.id}`}>{option.text}</label>
                            </div>
                        ))
                    }
                </div>
                <div className="sc-primary-btn" style={{ marginBottom: 5 }}>
                    <a className="secondary-btn2" href="#" onClick={handleAcceptClick}><span>Accept</span></a>
                </div>
            </div>
            <div className={"cookies-x"}>
                <FaTimes size={24} style={{ cursor: 'pointer' }} onClick={handleCloseClick} />
            </div>
        </div>
    );
};



export default CookieConsent;
