import Head from "../../layout/head/Head"
import Content from "../../layout/content/Content"
import { Row, Col, Button } from "reactstrap"
import { Accordian, PreviewAltCard } from "../../components/Component"
import { ApiKeyTable } from "./components/ApiKeyTable"
import {
  SpecialTable,
  Block,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  BlockDes,
  BackTo,
  OrderTable,
  LoginLogTable,
} from "../../components/Component";
import {
  Card, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown, Modal,
  ModalHeader,
  ModalBody,
  ModalFooter, CardBody, CardSubtitle, CardTitle, CardLink, CardText
} from "reactstrap";
import { Icon } from "../../components/Component"
import CodeSnippetTabs from "./components/CodeSnippetTabs"
import ApiFaq from "./components/ApiFaq"
import { useNavigate } from "react-router"
import { useEffect, useState } from "react"
import Cookies from "js-cookie"

function Api() {
  const [apiKeys, setApiKeys] = useState([]);
  const [webhook, setWebhook] = useState("");
  const [webhookSent, setWebhookSent] = useState("");
  const [secret, setSecret] = useState("");
  const token = Cookies.get('token');

  useEffect(() => {
    if (!token) {
      navigate("/signup")
    }
    let fetchKeys = async () => {
      try {
        const response = await fetch(`${global.SERVIP}/user/get-api-keys`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const { ok, api_keys, webhook, webhook_sent, secret } = await response.json();
        if (!ok) {
          console.log("greska")
          return;
        }
        setWebhook(webhook);
        setWebhookSent(webhook_sent)
        setSecret(secret);
        setApiKeys(api_keys)
      } catch (error) {
        console.error('Error fetching messages by month:', error);
        throw error;
      }
    }
    fetchKeys();
  }, [])
  const navigate = useNavigate()
  return (
    <>
      <Head title="Api page" />

      <Content>
        <h4 style={{ marginBottom: 10 }}>Welcome to your API page</h4>
        <p style={{ marginBottom: 20 }}>create/delete/charge your api keys and learn how to use TextLink API's</p>
        <Button color="primary" style={{ marginBottom: 40, width: '270px' }} onClick={() => {
          window.location.assign("https://docs.textlinksms.com");
        }}><Icon name="file-code"></Icon>Documentation</Button>


        <Col>
        <Row className="my-3">
  <h5 style={{ marginBottom: 10 }}>Our API'S:</h5>
  
  <Button onClick={() => window.open("https://docs.textlinksms.com", "_blank")} className="btn-dim" color="primary" style={{ width: '130px', marginRight: 5, marginBottom: 10 }}>
    <Icon name="file-code"></Icon>SMS API
  </Button>
  
  <Button onClick={() => window.open("https://docs.textlinksms.com/otp-phone-verification", "_blank")} className="btn-dim" color="primary" style={{ width: '170px', marginRight: 5, marginBottom: 10 }}>
    <Icon name="file-code"></Icon>Verification API
  </Button>
  
  <Button onClick={() => window.open("https://docs.textlinksms.com", "_blank")} className="btn-dim" color="primary" style={{ width: '160px', marginBottom: 10 }}>
    <Icon name="file-code"></Icon>Bulk SMS API
  </Button>
</Row>

          <Row style={{ display: 'flex', alignItems: 'stretch' }}>
            <Col lg="7" className="mb-4 mb-lg-0">
              <Card className="card-bordered h-100">
                <ApiKeyTable apiKeys={apiKeys} setApiKeys={setApiKeys} />
                <Button onClick={async () => {
                  try {
                    const response = await fetch(`${global.SERVIP}/user/generate-api-key`, {
                      method: 'POST',
                      headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                      }
                    });

                    if (!response.ok) {
                      throw new Error(`HTTP error! Status: ${response.status}`);
                    }

                    const { ok, api_key } = await response.json();
                    if (!ok) {
                      console.log("greska")
                      return;
                    }
                    setApiKeys([...apiKeys, api_key])
                  } catch (error) {
                    console.error('Error fetching messages by month:', error);
                    throw error;
                  }
                }} className="btn-dim" color="primary" style={{ margin: 10 }}>Generate new</Button>
              </Card>
            </Col>
            <Col lg="5">
              <Card className="card h-100" >
                <CardBody className="card-inner">
                  <CardTitle tag="h5">Webhook settings</CardTitle>
                  <p>
                  We can send an HTTP POST request to your server when you receive or send a message. <a href="https://docs.textlinksms.com/receive" target="_blank" style={{ color:'blue'}}>Learn more here</a>
                  </p><br></br>
                  Endpoint for received messages: <br></br>
                  <input className="form-control form-control-md" placeholder="Inbound webhook" type="text" value={webhook} onChange={e => setWebhook(e.target.value)}></input>
                  <br></br>
                  Endpoint for sent messages: <br></br>
                  <input className="form-control form-control-md" placeholder="Outbound webhook" type="text" value={webhookSent} onChange={e => setWebhookSent(e.target.value)}></input>
                  <br></br>
                    Secret:
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <input className="form-control form-control-md" placeholder="secret" type="text" value={secret} onChange={e => setSecret(e.target.value)}></input>
                      <Button color="primary" style={{ display: "inline", marginLeft: 10 }}  onClick={() => {
                        let ranstr = "";
                        let chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890';
                        for (let i = 0; i < 64; i++) {
                          ranstr += chars[Math.floor(Math.random() * chars.length)];
                        }
                        setSecret(ranstr);
                      }}>Generate</Button>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", margin: 10 }}>
                      <Button color="primary" onClick={() => {
                        if(!webhook && !webhookSent){
                          return alert("Please enter your webhooks");
                        }
                        fetch(global.SERVIP + '/user/test-webhook', {
                          method: 'POST',
                          headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + token
                          },
                          body: JSON.stringify({ webhook, webhook_sent: webhookSent, secret })
                        }).then(response => {
                          if (response.ok) {
                            response.json().then(res => {
                              if(!res.ok)
                                return alert("Test failed 1 ");
                              
                              if(!webhook && webhookSent){
                                if(res.ok_sent){
                                  alert("Outbound webhook is working!")
                                }
                                else{
                                  alert("Outbound webhook is not working. It should return status code 200. ");
                                }
                              }
                              else if(webhook && !webhookSent){
                                if(res.ok_received){
                                  alert("Inbound webhook is working!")
                                }
                                else{
                                  alert("Inbound webhook is not working. It should return status code 200. ");
                                }
                              }
                              else{
                                if(!res.ok_sent && !res.ok_received){
                                  alert("Webhooks are  not working! They should return status code 200. ")
                                }
                                else if (res.ok_sent && !res.ok_received){
                                  alert("Outbound webhook is working, but inbound webhook is not working. It should return status code 200. ");
                                }
                                else if (!res.ok_sent && res.ok_received){
                                  alert("Inbound webhook is working, but outbound webhook is not working. It should return status code 200. ");
                                }
                                else if (res.ok_sent && res.ok_received){
                                  alert("Both webhooks are working! ");
                                }
                              }
                            })
                          }
                          else {
                            alert("Test failed 2 ");
                          }
                        }).catch(e => {
                          alert("Test failed 3 ");
                        })
                      }}>Test webhooks</Button>
                      <Button color="primary" onClick={() => {
                        fetch(global.SERVIP + '/user/update-webhook', {
                          method: 'POST',
                          headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + token
                          },
                          body: JSON.stringify({ webhook, webhook_sent: webhookSent, secret })
                        }).then(response => {
                          if (response.ok) {
                            response.json().then(res => {
                              if (res.ok)
                                alert("Successfully updated webhook!")
                              else
                                alert("Could not update webhook 2")
                            })
                          }
                          else {
                            alert("Could not update webhook 3")
                          }
                        }).catch(e => {
                          console.log(e);
                          alert("Could not update webhook 4")
                        })
                      }}>Save</Button>
                    </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row className="my-3">
            <Col lg="7" className="mb-4 mb-lg-0">

              <CodeSnippetTabs />

            </Col>
            <Col lg="5">
              <Card className="card h-100" style={{ padding: 20 }} >
                <h5>API FAQ</h5>
                <ApiFaq />
              </Card>
            </Col>
          </Row>

        </Col >
      </Content >
    </>
  )
}
export default Api