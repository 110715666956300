import React, { Suspense } from 'react'
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom'

import Home from "./Home";
import About from "./About";
import News from "./News";
import Contact from "./Contact";
import NewsDetails from "./news-details";
import Faq from "./faq";
import History from "./history";
import Price from "./price";
import { Switch} from 'react-router-dom';

import Services from './services';
import ServiceDetails from './services-details';

import TextLinkService from '../TextLinkPages/Services/TextLinkService';
import UseYourOwnNumbers from '../TextLinkPages/Services/UseYourOwnNumbers';
import SmsApi from '../TextLinkPages/Tools/SMS Api/SmsApi';
import BulkSmsApi from '../TextLinkPages/Tools/SMS Api/BulkSmsApi';
import VerificationApi from '../TextLinkPages/Tools/SMS Api/VerificationApi';
import AdvancedBulkSms from '../TextLinkPages/Tools/No-Code/AdvancedBulkSms';
import ServicesOverview from '../TextLinkPages/Services/ServicesOverview';
import ToolsOverview from '../TextLinkPages/Tools/ToolsOverview';
import UseYourOwnNumbersPricing from '../TextLinkPages/Services/UseYourOwnNumbersPricing';
import SellRegular from '../TextLinkPages/SellSMS/SellRegular';
import SellForProviders from '../TextLinkPages/SellSMS/SellForProviders';
import LoginPage from '../TextLinkPages/LogReg/LoginPage';
import RegisterPage from '../TextLinkPages/LogReg/RegisterPage';
import SignIn from '../TextLinkPages/LogReg/SignIn';
import SignUp from '../TextLinkPages/LogReg/Signup';
import Admin from '../Dashboard/Admin';
import ThemeProvider from '../Dashboard/layout/provider/Theme';
import RouterAdmin from '../Dashboard/route/Index';
import TextLinkServicePricing from '../TextLinkPages/Services/TextLinkServicePricing';
import ForgotPassword from '../TextLinkPages/LogReg/ForgotPassword';
import ResetPassword from '../TextLinkPages/LogReg/ResetPassword';
import PaymentSuccess from '../Dashboard/pages/Pricing/PaymentSuccess';
import Tos from '../TextLinkPages/Tos/Tos';
import PrivacyPolicy from '../TextLinkPages/Tos/PrivacyPolicy';
import DownloadApp from '../TextLinkPages/Other/DownloadApp';
import TruckingDispatch from '../TextLinkPages/Industries/TruckingDispatch';
import Marketing from '../TextLinkPages/Industries/Marketing';
import UserVerification from '../TextLinkPages/Industries/SAAS';
import WebTextingApp from '../TextLinkPages/Tools/WebTextingApp';
import SAAS from '../TextLinkPages/Industries/SAAS';
import VerifySubuser from '../TextLinkPages/LogReg/VerifySubuser';
import ProductComparisonTable from '../TextLinkPages/Other/ProductComparisonTable';
import Cookies from '../TextLinkPages/Tos/Cookies';
import SignUpAppSumo from '../TextLinkPages/LogReg/SignUpAppSumo';

function Landing(){
  return(

    <Routes>
                <Route path="/" element={<Home />}></Route>

                <Route path="/textlinkservice" element={<TextLinkService/>}></Route>
                <Route path="/selfhostedservice" element={<UseYourOwnNumbers />}></Route>

                <Route path="/smsapi" element={<SmsApi />}></Route>                
                <Route path="/bulksmsapi" element={<BulkSmsApi />}></Route>     
                <Route path="/verificationapi" element={<VerificationApi />}></Route>             
                <Route path="/bulksms" element={<AdvancedBulkSms />}></Route> 
                <Route path="/web-sms-app" element = {<WebTextingApp/>}/>

                        
                <Route path="/trucking" element={<TruckingDispatch />}></Route>         
                <Route path="/sms-marketing" element={<Marketing />}></Route>         
                <Route path="/saas" element={<SAAS/>}/>

                <Route path="/Contact" element={<Contact />}></Route>    
                <Route path="/smsservicesoverview"   element={<ServicesOverview />}></Route>       
                <Route path="/smstoolsoverview" element={<ToolsOverview />}></Route>                    
                <Route path="/payment-success" element = {<PaymentSuccess/>}/>
                <Route path="/tos" element={<Tos/>}/>
                <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
                <Route path="/cookies" element={<Cookies />} />

                <Route path="/faq" element={<Faq />}></Route>
                <Route path="/download-app" element={<DownloadApp />}></Route>  
                <Route path="/pricing" element={<UseYourOwnNumbersPricing />}></Route>    
                <Route path="/services" element={<Services/>}></Route>              
                <Route path="/services-details" element={<ServiceDetails/>}></Route>        
                <Route path="/sms-product-comparasings" element={<ProductComparisonTable/>}/>

                <Route path="/about" element={<About/>}></Route>
                <Route path="/signin" element={<SignIn/>}/>
                <Route path="/signup" element={<SignUp/>}/>
                <Route path="/appsumo-signup" element={<SignUpAppSumo/>}/>
                <Route path="forgot-password" element={<ForgotPassword/>}/>
                <Route path="/auth/reset-password/:token" element={<ResetPassword />} />  
                <Route path="/verify-subuser/:id/:token" element={<VerifySubuser />} />

                </Routes>

  )
}
export default function RouterPage() {
  const isLoggedIn = true
  return (
    <div>
        <Router>
            <Landing/>
            <RouterAdmin />
        </Router>
    </div>
  )
}
