import React, { useState, useCallback, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Form, Input, Button, notification } from 'antd';
// eslint-disable-next-line import/no-extraneous-dependencies
import { FacebookOutlined, TwitterOutlined } from '@ant-design/icons';
import { AuthWrapper } from './style';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import Head from '../../components/Head';
const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;

function SignIn() {
  const [form] = Form.useForm();
  const navigate = useNavigate()
  const signedIn = Cookies.get('token') ? true : false;

  const handleCredentialResponse = (response) => {
    handleGoogleSignIn(response.credential)
  };
  useEffect(() => {
    if (global.iskoristio) return;
    global.iskoristio = true;
    window.google.accounts.id.initialize({
      client_id: '493483959965-rspkljm6ergq7bth5t2g4aac1psd7cvj.apps.googleusercontent.com',
      callback: handleCredentialResponse
    });
    //window.google.accounts.id.prompt();


    window.google.accounts.id.renderButton(document.getElementById("divGbox"), {
      text: "continue_with",
      shape: "rectangular",
      width: "220px"
    });
  }, [])

  useEffect(() => {
    if (signedIn) {
      navigate('/dashboard');
    }
  }, [signedIn, navigate]);
  const handleLogin = async (values) => {
    const url = global.SERVIP + '/auth/login'; // Replace with your server URL

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify({
          email: values.username, // Assuming the username field is used for the email
          password: values.password
        })
      });

      const data = await response.json();
      if (data.ok) {
        Cookies.set('token', data.token, { expires: 15, path: '/' });
        if (data.subuser) {
          Cookies.set('subuser', true, { expires: 15, path: '/' });
        }
        else {
          Cookies.set('subuser', false, { expires: 15, path: '/' });

        }
        navigate('/dashboard'); // redirect to the dashboard or appropriate page
      } else {
        notification.error({
          message: data.message,
        })
      }
    } catch (error) {
      alert('Login failed');
    }
  };

  const handleGoogleSignIn = async (credential) => {
    try {
      const response = await fetch(global.SERVIP + '/auth/google-sign-in', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          credential
        })
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      if (data.ok) {
        Cookies.set('token', data.token, { expires: 15, path: '/' });
        if (data.subuser) {
          Cookies.set('subuser', true, { expires: 15, path: '/' });
        }
        else {
          Cookies.set('subuser', false, { expires: 15, path: '/' });
        }
        navigate('/dashboard');
      } else {
        notification.error({
          message: data.message,
        })
        window.google.accounts.id.prompt();
      }

    } catch (error) {
      console.error('Login error', error);
    }
  };

  const handleSubmit = (values) => {
    handleLogin(values);
  };

  return (
    <AuthWrapper>
      <Head title="Sign in Page" />

      <div className="auth-contents">
        <Form name="login" form={form} onFinish={handleSubmit} layout="vertical">
          <h3 style={{ marginBottom: '30px', textAlign: 'center' }}>Welcome back.</h3>
          <div id="divGbox" style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", margin: 10 }}></div>
          <p style={{ textAlign: 'center', marginTop: "15px", marginBottom: '15px', fontWeight: "bold", color: "black" }}>
            or
          </p>

          <Form.Item
            name="username"
            rules={[{ message: 'Please input your username or Email!', required: true }]}
            initialValue="name@example.com"
            label="Username or Email Address"
          >
            <Input />
          </Form.Item>
          <Form.Item name="password" initialValue="123456" label="Password">
            <Input.Password placeholder="Password" />
          </Form.Item>
          <div className="auth-form-action">

            <NavLink className="forgot-pass-link" to="/forgot-password">
              Forgot password?
            </NavLink>
          </div>
          <Form.Item>
            <Button className="btn-signin" htmlType="submit" type="primary" size="large" style={{ width: '100%', }}>
              Sign in
            </Button>
          </Form.Item>
          <p style={{ fontWeight: 'normal', textAlign: 'center' }}>
            Don&rsquo;t have an account? <a style={{ fontWeight: 'bolder' }} href="/signup">Sign up now</a>
          </p>
        </Form>
      </div>
    </AuthWrapper>
  );
}

export default SignIn;