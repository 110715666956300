import { Link } from 'react-router-dom';
export default function QuickLinks(){
    return(
        <>
        <h6 className="widget-title text-black pb-15 mb-35" style={{color: 'black'}}>Quick links</h6>
        <ul className="fot-list fot-flex" style={{color:'black'}}>
            <li><Link to="/faq" style={{color:'black'}}>FAQs</Link></li>
            <li><Link to="/contact" style={{color:'black'}}>Contact Us</Link></li>
            <li><Link to="/signup" style={{color:'black'}}>Sign up</Link></li>
            <li><Link to="/privacy-policy" style={{color:'black'}}>Privacy policy</Link></li>
            <li><Link to="/tos" style={{color:'black'}}>Tearms of service</Link></li>
            <li><Link to="/cookies" style={{color:'black'}}>Cookies</Link></li>


        </ul>
        </>
    )
}