import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import MainMenu from './mainmenu';
import SideBar from './SideBar';
import { useNavigate } from 'react-router-dom';
import CookieConsent from '../components/CookieConsent';

export default function HeaderOne() {
    const navigate = useNavigate();

    const handleClickTelegram = () => {
        window.open('https://t.me/markolekic', '_blank', 'noopener,noreferrer');
    };

    const handleEmailClick = () => {
        window.location.href = 'mailto:info@textlinksms.com';
    };

    const openSideBar = () => {
        document.body.classList.add('on-side');
    };

    useEffect(() => {
        window.addEventListener('scroll', isSticky);
        return () => {
            window.removeEventListener('scroll', isSticky);
        };
    });

    const isSticky = (e) => {
        const header = document.querySelector('.main-header-area');
        const scrollTop = window.scrollY;
        scrollTop >= 250 ? header.classList.add('sticky-menu') : header.classList.remove('sticky-menu');
    };

    const listItemStyles = {
        cursor: 'pointer',
        transition: 'transform 0.3s ease, box-shadow 0.3s ease',
    };

    const listItemHoverStyles = {
        transform: 'scale(1.05)',
    };

    const [emailHover, setEmailHover] = useState(false);
    const [telegramHover, setTelegramHover] = useState(false);

    return (
        <>
            <CookieConsent></CookieConsent>
            <div className="body-overlay activemode"></div>
            <SideBar />
            <header className="header-top-area">
                <div className="container d-none d-sm-block">
                    <div className="row header-top gx-0" style={{ marginBottom: 15, marginTop: '8px' }}>
                        <div className="col-xl-4 col-lg-3 d-none d-lg-inline-block">
                            <div className="logo-area pl-30">
                                <Link className="head-logo-1" to="/">
                                    <img src="assets/img/logo/logo2.png" alt="Header-logo" />
                                    <a style={{ fontFamily: 'Nunito', fontSize: '1.5em', fontWeight: 'bold', color: 'black' }}>TextLink</a>
                                </Link>
                            </div>
                        </div>
                        <div className="col-xl-8 col-lg-9" style={{ display: 'flex' }}>
                            <ul className="right-query list-none d-sm-flex justify-content-center justify-content-lg-end">
                                <li>
                                    <div style={{ width: '40px' }}>
                                    </div>
                                </li>
                                <li
                                    onClick={handleEmailClick}
                                    style={emailHover ? { ...listItemStyles, ...listItemHoverStyles } : listItemStyles}
                                    onMouseEnter={() => setEmailHover(true)}
                                    onMouseLeave={() => setEmailHover(false)}
                                >
                                    <div className="icon"><i className="fas fa-envelope"></i></div>
                                    <div className="query-list">
                                        <span style={{ color: 'black' }}>Email Address</span>
                                        <p className="text-heding" style={{ color: 'black' }}>info@textlinksms.com</p>
                                    </div>
                                </li>
                               
                                <li>
                                    <Link className="theme_btn quote-btn" style={{ backgroundColor: '#f5f5f5' }} to="/signup">Sign up now</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="main-header-area">
                    <div className="container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div className="border-tops white-bg row align-items-center gx-0" style={{ backgroundColor: 'rgba(0, 0, 255, 0.0)' }}>
                            <div className="col-lg-10 col-6 text-start">
                                <div className="logo-area d-lg-none d-md-block" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', padding: 10 }}>
                                    <a style={{ fontFamily: 'Nunito', fontSize: '1.5em', fontWeight: 'bold', padding: '10px' }}>TextLink</a>
                                </div>
                                <div className="d-none d-lg-block" style={{ padding: '10px' }}>
                                    <MainMenu />
                                </div>
                            </div>
                            <div className="col-lg-2 col-6 text-end">
                                <ul className="right-nav mb-0 d-flex align-items-center justify-content-end">
                                    <li>
                                        <div style={{ backgroundColor: "rgba(0,0,0,0)" }} className='dissapierning-navbar'>
                                            <a onClick={openSideBar}><img src="assets/img/icon/menu.png" alt="Eclips" /></a>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
}
