import React, { useState } from "react";
import { Collapse } from "reactstrap";

const ApiFaq = ({ className, variation, ...props }) => {
  const [isOpen, setIsOpen] = useState("1");

  const toggleCollapse = (param) => {
    if (param === isOpen) {
      setIsOpen("0");
    } else {
      setIsOpen(param);
    }
  };

  return (
    <div className={[`accordion${variation ? " accordion-s" + variation : ""}${className ? " " + className : ""}`]} style={{marginTop:10}}>
      <div className="accordion-item">
        <div className={[`accordion-head${isOpen !== "1" ? " collapsed" : ""}`]} onClick={() => toggleCollapse("1")}>
          <h6 className="title">Do i need to put prefix on a number?</h6>
          <span className="accordion-icon"></span>
        </div>
        <Collapse className="accordion-body" isOpen={isOpen === "1" ? true : false}>
          <div className="accordion-inner">
            <p>
              Yes, you should send each message with + prefix (+1 123 456 7654). If you send a message with the wrong format, or the number doesnt exist, we won't charge you.
            </p>
           
          </div>
        </Collapse>
      </div>
      <div className="accordion-item">
        <div className={[`accordion-head${isOpen !== "2" ? " collapsed" : ""}`]} onClick={() => toggleCollapse("2")}>
          <h6 className="title">Can i choose sender country?</h6>
          <span className="accordion-icon"></span>
        </div>
        <Collapse className="accordion-body" isOpen={isOpen === "2" ? true : false}>
          <div className="accordion-inner">
            <p>
              Yes, depending on our available routes (Your SIM cards and devices). Depending on the country, you can choose which countries number the message will be sent from.
            </p>
           
          </div>
        </Collapse>
      </div>
  
    
    </div>
  );
};

export default ApiFaq;
