import React, { useContext, useEffect, useState } from "react";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import { Card, Modal, ModalBody, Badge } from "reactstrap";
import {
  Button, Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Col,
  Row,
  OverlineTitle,
  Sidebar,
  UserAvatar,
} from "../../components/Component";
import { useNavigate, useParams } from "react-router-dom";
import PurchaseHistory from "./components/PurchaseHistory";
import Cookies from "js-cookie";
import Swal from "sweetalert2";

import './UserPageStyles.css'; // Adjust the path as necessary

const userData = [
  {
    id: 1,
    avatarBg: "purple",
    name: "Abu Bin Ishtiyak",
    displayName: "Ishtiak",
    dob: "10 Aug, 1980",
    role: "Customer",
    checked: false,
    email: "info@softnio.com",
    balance: "35040.34",
    phone: "818474958",
    emailStatus: "success",
    kycStatus: "success",
    lastLogin: "10 Feb 2020",
    status: "Active",
    address: "2337 Kildeer Drive",
    state: "Kentucky",
    country: "Canada",
    designation: "UI/UX Designer",
    projects: "213",
    performed: "87.5",
    tasks: "587",
  },
  {
    id: 2,
    avatarBg: "purple",
    name: "Ashley Lawson",
    dob: "10 Sept, 1990",
    role: "Investor",
    email: "ashley@softnio.com",
    balance: "580.00",
    checked: false,
    phone: "1243941787",
    emailStatus: "success",
    kycStatus: "pending",
    lastLogin: "07 Feb 2020",
    status: "Pending",
    country: "United States",
    designation: "UI/UX Designer",
    projects: "213",
    performed: "87.5",
    tasks: "587",
  },]
const UserPage = () => {
  const [data] = userData;
  const token = Cookies.get('token');
  const isSubUser = Cookies.get("subuser") == "true"

  const [sideBar, setSidebar] = useState(false);
  const [user, setUser] = useState(userData[0]);
  const [userEmail, setUserEmail] = useState("")
  const [userCreatedAt, setUserCreatedAt] = useState("")
  const navigate = useNavigate();

  let { userId } = useParams();

  // grabs the id of the url and loads the corresponding data
  useEffect(() => {

    setUser(data[0]);
  }, [data]);


  useEffect(() => {
    if(!token)
    {
      navigate("/signup")
    }
    sideBar ? document.body.classList.add("toggle-shown") : document.body.classList.remove("toggle-shown");
  }, [sideBar])
  useEffect(() => {
    getUserEmail()
  }, [])
  // delete a note
  const getUserEmail = async () => {
    try {
      const token = Cookies.get('token'); // Retrieve the token from cookies
      if (!token) {
        console.log('No token found');
        return null;
      }
      const urlFunction = isSubUser? "get_subuser_info" : "get_user_info"

      const response = await fetch(global.SERVIP + "/user/"+urlFunction, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`  // Set the Authorization header with the token
        },
      });

      if (!response.ok) {
        console.error('Failed to retrieve email:', response.status);
        return null;
      }

      const data = await response.json();
      setUserEmail(data.info.email)
      setUserCreatedAt(data.info.createdAt)
      return data;
    } catch (error) {
      console.error('Error while fetching user email:', error);
      return null;
    }
  };
  function formatDateString(dateString) {
    const options = {
      year: 'numeric', month: 'short', day: 'numeric',
      hour: '2-digit', minute: '2-digit', hour12: true
    };
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-US', options).format(date);
  }

  const deleteUser = async () => {
    try {
      const token = Cookies.get('token'); // Retrieve the token from cookies
      if (!token) {
        console.log('No token found');
        return { ok: false, message: 'Authentication required' };
      }

      const response = await fetch(`${global.SERVIP}/user/delete_user`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}` // Set the Authorization header with the token
        }
      });

      if (!response.ok) {
        const errorData = await response.json();
        console.error('Failed to delete user:', errorData.message);
        return { ok: false, message: errorData.message };
      }

      const data = await response.json();
      return { ok: true, message: data.message };
    } catch (error) {
      console.error('Error while deleting user:', error);
      return { ok: false, message: 'Error deleting user' };
    }
  };
  const deleteAlert = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete my account",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await deleteUser()
        Swal.fire(response.message);
        if (response.ok)
          navigate("/")

      }
    });
  };
  return (
    <>
      <Head title="User Details"></Head>
      {userData[0] && (
        <Content>
          <BlockHead size="sm">
            <BlockBetween>
              <BlockHeadContent>
                <BlockTitle tag="h3" page>
                  {Cookies.get("subuser")=="true"?"User": "Admin"} / <strong className="text-primary small">{userEmail}</strong>
                </BlockTitle>
                <BlockDes className="text-soft">
                  <ul className="list-inline">

                    <li>
                      Account created at: <span className="text-base">{userCreatedAt && formatDateString(userCreatedAt)}</span>
                    </li>
                  </ul>
                </BlockDes>
              </BlockHeadContent>
              <BlockHeadContent>
                <Button
                  color="light"
                  outline
                  className="bg-white d-none d-sm-inline-flex"
                  onClick={() => navigate(-1)}
                >
                  <Icon name="arrow-left"></Icon>
                  <span style={{ color: 'black' }}>Back</span>
                </Button>
                <a
                  href="#back"
                  onClick={(ev) => {
                    ev.preventDefault();
                    navigate(-1);
                  }}
                  className="btn btn-icon btn-outline-light bg-white d-inline-flex d-sm-none"
                >
                  <Icon name="arrow-left" style={{ color: 'black' }}
                  ></Icon>
                </a>
              </BlockHeadContent>
            </BlockBetween>
          </BlockHead>

          <Block>
            <Card className="card-bordered">
              <div className="card-aside-wrap" id="user-detail-block">
                <div className="card-content">
                  <div className="card-inner">
                    <Block>
                      <BlockHead>
                        <BlockTitle tag="h5">Account Information and Actions</BlockTitle>
                        <p>Basic info, delete account, see usage, change password, ...</p>
                      </BlockHead>
                      {/* Buttons for Account Actions */}
                      <div className="account-actions">
                        <br />
                        <Button className="btn-dim" color="primary" onClick={() => navigate('/dashboard/change-password')} style={{ padding: 10, width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}>
                          <Icon name="exchange-v" style={{ marginRight: 3 }} />
                          Change Password</Button>

                        <Button className="btn-dim" color="primary" onClick={() => navigate('/dashboard/reset-password')} style={{ padding: 10, width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}>
                          <Icon name="undo" style={{ marginRight: 3 }} />
                          Reset Password</Button>
                          {(!(Cookies.get("subuser")== "true")) &&  <Button className="btn-dim" color="primary" onClick={() => navigate('/dashboard/team')} style={{ padding: 10, width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}>
                          <Icon name="user" style={{ marginRight: 3 }} />
                          Add Users To Organisation</Button>}
                        <div className="nk-divider divider md"></div>

                        <div className="support-section">
                          <Button className="btn-dim" color="info" outline={true} onClick={() => navigate('/contact')} style={{ padding: 5, width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}>
                            <Icon name="account-setting-alt" />
                            Contact Support</Button>
                          <p>
                            Need help with your account? Our support team is here to assist you with any issue or question you might have.
                            Write us at support@textlinksms.com
                          </p>
                        </div>

                      </div>

                    </Block>
                    {(!(Cookies.get("subuser")=="true")) &&
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', }}>
                      <div className="nk-divider divider md"></div>
                      <PurchaseHistory />
                      <div className="nk-divider divider md"></div>

                      <Button color="danger" onClick={() => { deleteAlert() }} style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", maxWidth: "200px" }}>
                        <Icon name="alert-c" style={{ marginRight: 3 }} />
                        Delete Account</Button>
                    </div> }
                  </div>
                </div>
              </div>
            </Card>
          </Block>
        </Content>
      )}
    </>
  );
};
export default UserPage;
