import React, { useEffect, useState } from "react";
import { CardTitle } from "reactstrap";
import { DataTableBody, DataTableHead, DataTableItem, DataTableRow } from "../../../../components/Component";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/esm/Button";

const DevicesTable = ({
  showSimsForDevice,
  devicesData,  // This is the fetched data
  selling,
  deleteDevice
}) => {
  // Other existing state and useEffect logic...
  if (!devicesData) {
    return (
      <a>No data available</a>
    )
  }
  return (
    <React.Fragment>
      <div className="card-inner">
        <div className="card-title-group">
          <CardTitle>
            <h6 className="title">
              <span className="me-2">Your devices {selling && "with selling enabled"}</span>{" "}
              <Link to={`${process.env.PUBLIC_URL}/transaction-crypto`} className="link d-none d-sm-inline">
                See History
              </Link>
            </h6>
          </CardTitle>
        </div>
      </div>
      <DataTableBody className="border-top" bodyclass="nk-tb-orders">
        <DataTableHead>
          <DataTableRow className="nk-tb-orders-type">
            <span>Id</span>
          </DataTableRow>
          <DataTableRow>
            <span>Name</span>
          </DataTableRow>
          <DataTableRow size="sm">
            <span>Num. of SIM's</span>
          </DataTableRow>
         
          
          <DataTableRow size="sm">
            <span>Delete</span>
          </DataTableRow>

        </DataTableHead>
        {devicesData && devicesData.map((item) => (
          <DataTableItem key={item.Id} onClick={() => showSimsForDevice(item)}>
            <DataTableRow>
              <span className="tb-lead">{item.Id}</span>
            </DataTableRow>
            <DataTableRow>
              {/* Replace this with the actual device name if available */}
              <span className="tb-lead">Device {item.Id}</span>
            </DataTableRow>
            <DataTableRow size="sm">
              <span className="tb-sub">{item.NumOfSIMs}</span>
            </DataTableRow>
           
            <DataTableRow size="sm">
              <span className="tb-sub"><img
                src="https://cdn-icons-png.flaticon.com/512/6861/6861362.png"
                alt="Delete"
                onClick={(e) => {
                  e.stopPropagation();
                  deleteDevice(item.Id);
                }}
                style={{ marginLeft: 5, fill: 'red', cursor: 'pointer' }}
                width={20}
                height={20}
              /></span>
            </DataTableRow>
          </DataTableItem>
        ))}
      </DataTableBody>
      <Button onClick={() => {
        window.location.replace('/dashboard/your-devices/get-started');
      }} color="primary" className="btn-sm" style={{ margin: 15 }}>
        How to add devices?
      </Button>
    </React.Fragment>
  );
};

export default DevicesTable;



