// MetaPixelEvents.js
import ReactPixel from 'react-facebook-pixel';
import Cookies from 'universal-cookie';

// Initialize Meta Pixel (optional here if you prefer central initialization)
// ReactPixel.init('YOUR_PIXEL_ID');

// Function to track Sign Up event
export const trackSignUp = (value, currency = 'USD') => {
  const cookies = new Cookies();
  if (cookies.get('targeting_cookies')?.toString() !== 'true')
    return;
  ReactPixel.track('Lead', {
    value: value,
  });
};

// Function to track Send Message event
// Function to track Contact event
export const trackContact = () => {
  const cookies = new Cookies();
  if (cookies.get('targeting_cookies')?.toString() !== 'true')
    return;
  ReactPixel.track('Contact');
};


// Function to track Purchase event
export const trackPurchase = (value, currency = 'USD') => {
  const cookies = new Cookies();
  if (cookies.get('targeting_cookies')?.toString() !== 'true')
    return;
  ReactPixel.track('Purchase', {
    value: value,
    currency: currency,
  });
};

// Function to track CompleteRegistration event
export const trackCompleteRegistration = (status, currency = 'USD') => {
  const cookies = new Cookies();
  if (cookies.get('targeting_cookies')?.toString() !== 'true')
    return;
  ReactPixel.track('CompleteRegistration', {
    content_name: 'Registration',
    status: status, // Optional detail about the registration, e.g., "successful"
  });
};

export const updateAdvancedMatching = (newMatchingData) => {
  const cookies = new Cookies();
  if (cookies.get('targeting_cookies')?.toString() !== 'true')
    return;
  const pixelOptions = {
    autoConfig: true,  // set to false if you have events that should not trigger the pixel (optional)
    debug: false,       // enable logs for debugging (optional)
  };
  ReactPixel.init('1481514672539173', newMatchingData, pixelOptions);
};
